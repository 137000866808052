import React from 'react';
import Helpers from './Helpers';

import './Pagination.css';

class Pagination extends React.Component {

	shouldComponentUpdate(nextProps, nextState) {
		let triggered = Helpers.update_trigger(this.props,nextProps);
		return triggered;
	}



	render() {
		let navigation_pages = this.props.calc_pagination(this.props.page_nr,this.props.total_pages,this.props.page_iterator_count)
		let prev =  this.props.page_nr-1;
		let next =  this.props.page_nr+1;
		return(
			<nav className='paginationWrapper'>
				<ul className='pagination'
					key = {this.props.category}
				>
					{this.props.page_nr>1 &&
					<li key = {"down"}
						className={'paginationItem paginationItem_down'}
						onClick = 	{() => {
							this.props.navigate("page",prev) 
						}
					}
	
					>
					<span className='paginationText material-icons '>
						&#xe408;
					</span>
					</li>
					}
					{
						navigation_pages.map((navigation_page,k) => {
							let cls = "";
							if(parseInt(this.props.page_nr)===parseInt(navigation_page)) {
								cls = "paginationItem_current"
							}							
							if(navigation_page-navigation_pages[k-1]>1) {
								if(navigation_page<navigation_pages[navigation_pages.length-1]) {
									cls = cls+" paginationEllipsis_before"
								}
								if(navigation_page===navigation_pages[navigation_pages.length-1]) {
									cls = cls+" paginationEllipsis_before"
								}
							}
							return(
								<li key = {k}
									className={'paginationItem '+cls}
									onClick = 	{() => {
										this.props.navigate("page",navigation_page) 
										}
									}
								>
									<span className='paginationText'>
									{navigation_page}
									</span>
								</li>
							)	
						})
					}
					{this.props.page_nr<navigation_pages[navigation_pages.length-1] &&
					<li key = {"up"}
						className={'paginationItem paginationItem_up'}
						onClick = 	{() => {
							this.props.navigate("page",next) 
						}
					}
					>
						<span className='paginationText material-icons '>
							&#xe409;
						</span>
					</li>
					}
				</ul>
			</nav>
		)
	}
}

export default Pagination
