import React from 'react';
import MetaTags from 'react-meta-tags';
import './App.css';
import Busy from './Busy';
import Helpers from './Helpers';
import Pagination from './Pagination';
import PostPagination from './PostPagination';
import Breadcrumbs from './Breadcrumbs';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import PostListing from './PostListing';
// import MainNavigation from './MainNavigation';
import MainMenu from './MainMenu';
import Menu from './Menu';
import Post from './Post';
import Webpage from './Webpage';
import Contacts from './Contacts';

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			api_base_url: "https://wp.rafenew.world/wp-json/wp/v2",

			meta:  {
				title: "rafe new world",
				description: "Data, Design & Storytelling",
				keywords: "rafe new world, rafenew.world, blog, Raphael Röthlin, Data, Design, Storytelling",
				url: "https://rafenew.world",
				image: "%PUBLIC_URL%/ogimage.jpg",
			},

			meta_default: {},
			
			type: 'page',
			layout: 'home',
			
			categories: {},
			categories_slugs: {},
			category_id: "*",

			tag_id: "*",
			tags: {},
			tags_slugs: {},

			posts: {},
			posts_list: [],
			post_id: "*",
			post: {},

			webpages: {},
			webpages_slugs: {},
			webpage_id: "*",
			webpage: {},
			
			page_nr: 1,
			total_pages: 100,
			navigation_pages: [],
			page_iterator_count: 5,
			x_wp_total: 0,
			x_wp_totalpages: 0,
			per_page: 12,

			search_term: "",

			menus: {},
			menu_visible: "menu_hidden",

			mediaSets: {},
			// frame_ready: false,

			ready: false,
			error: false,

			fields: {
				tag: 		"_fields=id,name,slug,description",
				post: 		"_fields=id,date,slug,status,type,title,content,featured_media,sticky,categories,tags",
				webpage: 	"_fields=id,date,slug,status,type,title,content,featured_media,sticky,categories,tags",
				category: 	"_fields=id,count,name,slug,parent",
				media: 		"_fields=id,slug,title,caption,alt_text,mime_type,media_type,media_details"
			},
			contacts: [
				{
					id: "linkedin",
					name: "LinkedIn",
					url: "https://linkedin.com/in/rafeman",
					handle: "LI"
				},
				{
					id: "youtube",
					name: "YouTube",
					url: "https://www.youtube.com/channel/UCR_LDIGZyEMYnkWx296fbfA",
					handle: "YT"
				},
				{
					id: "instagram",
					name: "Instagram",
					url: "https://www.instagram.com/rafeman79/",
					handle: "IN"
				},
				{
					id: "facebook",
					name: "Facebook",
					url: "https://www.facebook.com/raphael.roethlin.1",
					handle: "FB"
				},
				
				{
					id: "spotify",
					name: "Spotify",
					url: "https://open.spotify.com/user/116682204",
					handle: "SP"
				},
				

			]
		}
		this.navigate = this.navigate.bind(this)
		this.set_category = this.set_category.bind(this)
		this.set_page = this.set_page.bind(this)
		this.set_post = this.set_post.bind(this)
		this.set_tag = this.set_tag.bind(this)
		this.set_home = this.set_home.bind(this)
		this.toggle_menu = this.toggle_menu.bind(this)

		this.set_search = this.set_search.bind(this);
		this.handle_search_field = this.handle_search_field.bind(this);
		this.calc_pagination = this.calc_pagination.bind(this);
	}

	componentDidMount() {
		this.init()
		// this.scale_fixer();

		this.setState({
			meta_default:this.state.meta
		})
		
		window.onpopstate = (event) => {
			// this.setState({
			// 	category_id: event.state.category_id,
			// 	post_id: event.state.post_id,
			// 	page_nr: event.state.page_nr,
			// }	
			// );
			window.location.reload()
		};

		// this.fetch_menus()		
		// this.url_vars();

		// When the user scrolls the page, execute myFunction
		window.onscroll = () => {
			this.scale_fixer()
		};		
	}



	scale_fixer() {
		var header = document.getElementById("header");
		var app = document.getElementById("App");
		var hero_header = document.getElementById("heroHeader");
		var hero_height = hero_header.scrollHeight;

		if (window.pageYOffset >= hero_height) {
			header.classList.add("compacted")
			app.classList.add("header_compacted")
		}

		else {
			header.classList.remove("compacted");
			app.classList.remove("header_compacted")
		}

	}


	init() {
		let assets = {};
		let promises = [];
		promises.push(this.fetch_categories().then((categories) => {
			assets["categories"] = categories.categories
			assets["categories_slugs"] = categories.categories_slugs
			})
		)
		// promises.push(this.fetch_tags().then((tags) => {
		// 	assets["tags"] = tags
		// 	})
		// )

		promises.push(this.fetch_menus().then((menus) => {
			assets["menus"] = menus
		}))

		Promise.all(promises).then((promises) => {
			this.setState({
				categories : assets.categories,
				categories_slugs : assets.categories_slugs,
				menus : assets.menus,
				// tags: assets.tags
			})
			this.routing();

		})

		// this.fetch_posts_by_category("*",1).then((posts) => {
		// 	this.setState({
		// 		x_page : 1,
		// 		posts : posts.posts,
		// 		posts_list : posts.posts_list
		// 	})
		// 	this.fill_gaps()
		// })
	}

	fill_gaps() {
		let posts_local = JSON.parse(JSON.stringify(this.state.posts))
		let tags_local = JSON.parse(JSON.stringify(this.state.tags))
		let tags_slugs_local = JSON.parse(JSON.stringify(this.state.tags_slugs))
		let missing_tag_ids = [];
		Helpers.o2a(posts_local).items.map((post,key) => {
			post.tags.map((tag_id,key) => {
				if(!this.state.tags.hasOwnProperty(tag_id)) {
					missing_tag_ids.push(tag_id);
				}
				return false;
			})
			return false;
		})
		// let nr = 0;
		if(missing_tag_ids.length>0) {
			let missing_tag_ids_str = missing_tag_ids.join(",");
			this.go_fetch(this.state.api_base_url+"/tags/?include="+missing_tag_ids_str+"&"+this.state.fields.tag+"&per_page=100",false)
			.then((tags) => {
				tags.map((tag,key) => {
					tags_local[tag.id] = tag;
					tags_slugs_local[tag.slug] = tag.id;
					// nr++;
					return false;
				})
				// console.log("fetched "+nr+" tags from "+missing_tag_ids.length)
				this.setState({
					tags:tags_local,
					tags_slugs: tags_slugs_local
				})
			})	
		}
		this.setState({
			ready:true
		})
	}

	componentDidUpdate() {
		// document.getElementById("header").classList.remove("compacted");
		// window.scrollTo(0, 0)
		document.title = this.state.meta.title;
		// this.scale_fixer()
		
	}

	open_url(url) {
		window.open(url)
	}


	routing(){
		/// ? UND & HASHES PRÜFEN UND ZUORDNEN !!!

		let p = Helpers.getUrlVar("p");

		let vars = [];
		let hashes = [];
		let hashes_0 = window.location.pathname.split('/');
	
		hashes_0 = hashes_0.filter(n => n.length > 0)
		const non_qualified = ["build","rnw"];

		// hashes_0 = ["rnw","build","category","data-intelligence"];
		hashes_0.map((stem,index) => {
			if(non_qualified.indexOf(stem)<=-1) {
				hashes.push(stem);
			}
			return false
		})
		let id = -1;
		let page_index = -1;
		let page = 1
		page_index = hashes.indexOf("page");
		if(page_index>-1) {
			page = parseInt(hashes[page_index+1])
		}

		// console.log(hashes)

		// BY POST
		//////////////
		if(p) {
			id = p;
			this.navigate("post",id)
		}
		else if(hashes.length===1) {
			id = hashes[0];
			this.navigate("post",id)
		}
		else if(hashes.length>1) {

			//////////////
			// BY WEBPAGE
			//////////////
			if(hashes[0]==="webpage") {
				id = hashes[1];
				if(this.state.webpages.hasOwnProperty(id)) {
					id = id*1;
				}
				else if(this.state.webpages_slugs.hasOwnProperty(id)) {
					id = this.state.webpages_slugs[id];
				}
				this.navigate("webpage",id)
			}

			//////////////
			// BY CATEGORY
			//////////////
			if(hashes[0]==="category") {
				id = hashes[1];
				if(this.state.categories.hasOwnProperty(id)) {
					id = id*1;
				}
				else if(this.state.categories_slugs.hasOwnProperty(id)) {
					id = this.state.categories_slugs[id];
				}
				if(page_index<=-1) {
					this.navigate("category",id)
				}
				else {
					this.setState({
						category_id : id
					})
					this.navigate("page",page)
				}
			}
			//////////////
			// BY TAG
			//////////////
			if(hashes[0]==="tag") {
				id = hashes[1];
				if(this.state.tags.hasOwnProperty(id)) {
					id = id*1;
				}
				else if(this.state.tags_slugs.hasOwnProperty(id)) {
					id = this.state.tags_slugs[id];
				}
				else {
					let tags_local = JSON.parse(JSON.stringify(this.state.tags))
					this.fetch_tag(id).then((tag) => {
						tags_local[tag.id] = tag;
						this.setState({
							tags:tags_local
						})
						id = tag.id
						if(page_index<=-1) {
							this.navigate("tag",id)
						}
						
						else {
							this.setState({
								tag_id : id
							})
							this.navigate("page",page)
						}
					})
				}
			}

			//////////////
			// BY SEARCH TERM
			//////////////
			if(hashes[0]==="search") {
				let search_term = hashes[1];
				if(search_term.length>1) {
					search_term = decodeURI(search_term);
					this.setState({
						search_term:search_term,
						page_nr: page
					})
				}
				this.set_search()
				// if(page_index>0) {
				// 	this.navigate("page",page)
				// }				
			}

			
			else if(page_index>-1) {
				this.navigate("page",page)
			}	
			// else {
			// 	this.set_home() 
			// }	
		}
		else {
			this.set_home();
		}
	

		// for(var i = 0; i < hashes.length; i++) {
		// 	// hash = hashes[i].split('=');
		// 	// vars.push(hash[0]);
		// 	// vars[hash[0]] = hash[1];
		// }
		return vars;
	}
	
	pushing_state(state,name,path_name) {
		// console.log(window.location)
		// console.log(name,path_name)
		let path = window.location.origin+""+path_name+"/";
		let title = "rafe new world – Data, Design & Storytelling";
		name = Helpers.getDecodedString(name);
		if(name.length>0) {
			title = name+""
		}

		let meta_local = JSON.parse(JSON.stringify(this.state.meta))
		meta_local.title = title;

		this.setState({
			meta:meta_local
		})
		if(path_name.length<=0) {

		}
		// console.log(path)
		window.history.pushState(state,name,path);
	}


	// url_vars() {
	// 	let type = "page";
	// 	let p = Helpers.getUrlVar("p");
	// 	let page = Helpers.getUrlVar("page");
	// }

	navigate(type,id) {
		switch(type) {
			case "category":	this.set_category(id);
			break;
			case "tag":			this.set_tag(id);
			break;
			case "post":		this.set_post(id);
			break;
			case "webpage":		this.set_webpage(id);
			break;
			case "page":		this.set_page(id);
			break;
			case "custom":		this.open_url(id);
			break;
			default: 			this.set_category(id);
		}

		let layout = "list"
		if(this.state.category_id==="*" && this.state.tag_id==="*" && this.state.page_nr==="1") {
			layout = "home"
		}
		else {
			layout = this.state.type
		}
		this.setState({
			menu_visible:"menu_hidden",
			layout:layout,
			// search_term: this.state.search_term
		})

		window.scrollTo(0, 0)

	}

	handle_search_field(event) {
		this.setState({
			search_term: event.target.value
		});
	}

	set_search() {
		// let search_term = document.getElementById("searchField").value
		let search_term = this.state.search_term;
		this.fetch_posts_by_search(search_term,1).then((posts) => {
			this.setState({
				search_term: search_term,
				tag_id : "*",
				post_id : "*",
				category_id : "*",
				type: "page",
				page_nr : this.state.page_nr,
				posts : posts.posts,
				posts_list : posts.posts_list
			})
			// this.fetch_mediaSets(posts.posts);

		})
		window.scrollTo(0, 0)
		this.pushing_state(this.state,encodeURI(this.state.search_term),"/search/"+encodeURI(this.state.search_term))

	}

	set_page(page_nr) {
		if(this.state.search_term!=="") {
			this.fetch_posts_by_search(this.state.search_term,page_nr).then((posts) => {
				this.setState({
					page_nr : page_nr,
					posts : posts.posts,
					posts_list : posts.posts_list,
					type: "page",
					post_id: "*"
				})
				this.fill_gaps();
				this.pushing_state(this.state,encodeURI(this.state.search_term),"/search/"+encodeURI(this.state.search_term)+"/page/"+page_nr)
			})
		}
		else if(this.state.tag_id==='*') {
			this.fetch_posts_by_category(this.state.category_id,page_nr).then((posts) => {
				this.setState({
					page_nr : page_nr,
					posts : posts.posts,
					posts_list : posts.posts_list,
					type: "page",
					post_id: "*"
				})
				this.fill_gaps();
				if(this.state.category_id==='*') {
					if(this.state.search_term!=='') {
						this.pushing_state(this.state,this.state.search_term,"/search/"+encodeURI(this.state.search_term)+"/page/"+page_nr)
					}
					else {
						this.pushing_state(this.state,"","/page/"+page_nr)
					}
				}
				else {
					this.pushing_state(this.state,this.state.categories[this.state.category_id].name,"/category/"+this.state.categories[this.state.category_id].slug+"/page/"+page_nr)
				}
			})
		}

		
		else {
			this.fetch_posts_by_tag(this.state.tag_id,page_nr).then((posts) => {
				this.setState({
					page_nr : page_nr,
					posts : posts.posts,
					posts_list : posts.posts_list,
					type: "page",
					post_id: "*"
				})
				this.pushing_state(this.state,this.state.tags[this.state.tag_id].name,"/tag/"+this.state.tags[this.state.tag_id].slug+"/page/"+page_nr)

				this.fill_gaps();

				//this.pushing_state(this.state,this.state.posts[post_id],this.state.posts[post_id].slug)
			})
		}
	}

	set_webpage(webpage_id) {
		let category_id = "*"
		let tag_id = "*"
		if(this.state.webpages.hasOwnProperty(webpage_id)) {
			this.setState({
				webpage_id : webpage_id,
				post_id : "*",
				type: "webpage",
				webpage : this.state.webpages[webpage_id],
				tag_id : tag_id,
				category_id : category_id
			})
			this.fill_gaps();
			this.pushing_state(this.state,this.state.webpages[webpage_id].title.rendered,"/"+this.state.webpages[webpage_id].slug)
		}
		else {
			this.fetch_webpage(webpage_id).then((webpages) => {
				webpage_id = webpages.id;
				this.setState({
					webpage_id : webpage_id,
					post_id : "*",
					type : "webpage",
					webpage : webpages.webpages[webpage_id],
					webpages : webpages.webpages,
					webpages_slugs : webpages.webpages_slugs,
					tag_id : tag_id,
					category_id : category_id
				})
				this.fill_gaps();
				this.pushing_state(this.state, webpages.webpages[webpages.id].title.rendered, "/webpage/"+this.state.webpages[webpages.id].slug);
			})
		}
	}

	set_post(post_id) {
		let tag_id = this.state.tag_id;
		let category_id = this.state.category_id;
		if(this.state.posts.hasOwnProperty(post_id)) {
			if(this.state.posts[post_id].categories.indexOf(category_id)<=-1) {
				category_id = "*"
			}
			else if(this.state.posts[post_id].tags.indexOf(tag_id)<=-1) {
				tag_id = "*"
			}
			this.setState({
				post_id : post_id,
				post : this.state.posts[post_id],
				type: "post",
				tag_id : tag_id,
				category_id : category_id
			})
			this.fill_gaps();
			this.pushing_state(this.state,this.state.posts[post_id].title.rendered,"/"+this.state.posts[post_id].slug)
		}
		else {
			this.fetch_post(post_id).then((posts) => {
				post_id = posts.id;
				
				if(posts.posts[post_id].categories.indexOf(category_id)<=-1) {
					category_id = "*"
				}
				else if(posts.posts[post_id].tags.indexOf(tag_id)<=-1) {
					tag_id = "*"
				}
				this.setState({
					post_id : posts.id,
					post : posts.posts[posts.id],
					type : "post",
					posts : posts.posts,
					tag_id : tag_id,
					category_id : category_id
				})
				this.fill_gaps();
				this.pushing_state(this.state, posts.posts[posts.id].title.rendered, "/"+this.state.posts[posts.id].slug);
			})
		}
		if(this.state.posts_list.indexOf(post_id)<=-1) {
			this.setState({
				search_term:""
			})
		}
	}

	async fetch_post(post_id) {
		let posts_local = JSON.parse(JSON.stringify(this.state.posts))
		let id = post_id;
		let post;
		if(!isNaN(post_id*1)) {
			post = await this.go_fetch(this.state.api_base_url+"/posts/"+post_id+"?"+this.state.fields.post,false);
			id = post.id;
			posts_local[post.id] = post;
		}
		else {
			let response = await this.go_fetch(this.state.api_base_url+"/posts?slug="+post_id+"&"+this.state.fields.post,false);
			post = response[0]
			id = post.id;
			posts_local[post.id] = post;
		}
		// ----> PREPROCESS ?!?
		posts_local = this.preprocess_posts(posts_local)
		if(!this.state.mediaSets.hasOwnProperty(post.featured_media)) {
			let x_mediaSets_local = JSON.parse(JSON.stringify(this.state.mediaSets))
			if(post.hasOwnProperty("featured_media")) {
				if(post.featured_media*1>-1) {
					let mediaSet = await this.fetch_mediaSet(post)
					x_mediaSets_local[mediaSet.id] = mediaSet;
					this.setState({
						mediaSets : x_mediaSets_local
					})
				}
			}		
		}
		return(
			{
				posts:posts_local,
				id:id
			}
		)
	}


	async fetch_webpage(webpage_id) {
		let webpages_local = JSON.parse(JSON.stringify(this.state.webpages))
		let slugs_local = JSON.parse(JSON.stringify(this.state.webpages_slugs))
		let id = webpage_id;
		let webpage;
		if(!isNaN(webpage_id*1)) {
			webpage = await this.go_fetch(this.state.api_base_url+"/pages/"+webpage_id+"?"+this.state.fields.webpage,false);
			id = webpage.id;
			webpages_local[webpage.id] = webpage;
			slugs_local[webpage.slug] = webpage.id;

		}
		else {
			let response = await this.go_fetch(this.state.api_base_url+"/pages?slug="+webpage_id+"&"+this.state.fields.webpage,false);
			webpage = response[0]
			id = webpage.id;
			webpages_local[webpage.id] = webpage;
			slugs_local[webpage.slug] = webpage.id;
		}
	
		// ----> PREPROCESS ?!?
		webpages_local = this.preprocess_posts(webpages_local)

		if(webpage.featured_media>0) {
			if(!this.state.mediaSets.hasOwnProperty(webpage.featured_media)) {
				let x_mediaSets_local = JSON.parse(JSON.stringify(this.state.mediaSets))
				let mediaSet = await this.fetch_mediaSet(webpage)
				x_mediaSets_local[mediaSet.id] = mediaSet;
				this.setState({
					mediaSets : x_mediaSets_local
				})
			}			
		}
	
		return(
			{
				webpages:webpages_local,
				webpages_slugs:slugs_local,
				id:id
			}
		)
	}

	async fetch_posts_by_search(search_term,page_nr) {
		let response = []
		let posts_local = JSON.parse(JSON.stringify(this.state.posts))
		let posts_list_local = [];
		response = await this.go_fetch(this.state.api_base_url+"/posts?search="+search_term+"&"+this.state.fields.post+"&page="+page_nr+"&per_page="+this.state.per_page,true);			
		response.map((post,key) => {
			posts_local[post.id] = post;
			posts_list_local.push(post.id)
			return false;
		})
		
		// ----> PREPROCESS ?!?
		posts_local = this.preprocess_posts(posts_local)
		
		this.fetch_mediaSets(posts_local);
		return {
			posts: posts_local,
			posts_list: posts_list_local
		}
		
	}


	preprocess_posts(posts) {
		Helpers.o2a(posts).items.map((post,key) => {
			if(!post.hasOwnProperty("featured_media") || post.featured_media*1<=0) {
				post.featured_media = -1
			}
			return false;
		})
		return posts
	}

	set_tag(tag_id) {
		if(this.state.tags.hasOwnProperty(tag_id)) {
			this.setState({
				tag_id : tag_id,
				category_id : "*",
				type: "page",
				x_page: 1,
				post_id: "*",
				search_term:""
			})
			this.fill_gaps();
		}
		else {
			this.fetch_tags(tag_id).then((tags) => {
				this.setState({
					tag_id : tag_id,
					category_id : "*",
					tags : tags,
					type: "page",
					x_page: 1,
					post_id: "*"
				})
				this.fill_gaps();
			})
		}

		this.fetch_posts_by_tag(tag_id,1).then((posts) => {
			this.setState({
				x_page : 1,
				posts : posts.posts,
				posts_list : posts.posts_list
			})
			this.pushing_state(this.state,this.state.tags[this.state.tag_id].name,"/tag/"+this.state.tags[this.state.tag_id].slug)

			// this.fetch_mediaSets(posts.posts);

		})
	}
	set_category(category_id) {
		let page_nr = this.state.page_nr;
		if(this.state.category_id!==category_id) {
			page_nr = 1;
		}
		if(this.state.categories.hasOwnProperty(category_id) || category_id==="*") {
			this.setState({
				category_id : category_id,
				tag_id : "*",
				type: "page",
				page_nr: page_nr,
				post_id: "*",
				search_term:""
			})
			this.fill_gaps();
		}
		else {
			this.fetch_category(category_id).then((categories) => {
				this.setState({
					category_id : category_id,
					tag_id : "*",
					categories : categories.categories,
					categories_slugs : categories.categories_slugs,
					type: "page",
					page_nr: page_nr,
					post_id: "*"
				})
				this.fill_gaps();
			})
		}

		this.fetch_posts_by_category(category_id,1).then((posts) => {
			this.setState({
				page_nr : page_nr,
				posts : posts.posts,
				posts_list : posts.posts_list
			})
			// this.fetch_mediaSets(posts.posts);

		})
		if(this.state.categories.hasOwnProperty(category_id)) {
			this.pushing_state(this.state,this.state.categories[category_id].name,"/category/"+this.state.categories[category_id].slug)
		}
		else {
			this.pushing_state(this.state,"","")
		}
	}
	
	async fetch_category(id) {
		let categories_local = JSON.parse(JSON.stringify(this.state.categories))
		let slugs_local = JSON.parse(JSON.stringify(this.state.categories_slugs))
		const category = await this.go_fetch(this.state.api_base_url+"?category="+id+"&"+this.state.fields.category,true);
		categories_local[category.id] = category;
		slugs_local[category.slug] = category.id;
		return({
			categories:categories_local,
			categories_slugs:slugs_local
		})
	}
	
	async fetch_tag(id) {
		let tag;
		if(!isNaN(id)) {
			tag = await this.go_fetch(this.state.api_base_url+"/tags/"+id+"&"+this.state.fields.tag,false);
		}
		else  {
			tag = await this.go_fetch(this.state.api_base_url+"/tags?slug="+id+"&"+this.state.fields.tag,false);
		}
		return(tag[0])
	}

	async fetch_menus() {
		let promises = [];
		const response = await this.go_fetch("https://wp.rafenew.world/wp-json/wp-api-menus/v2/menus",false);
		let menus_local = {}
		response.map((item,key) => {
			promises.push(this.go_fetch(item.meta.links.self,false))
			return false;
		})
		await Promise.all(promises).then((promises) => {
			promises.map((item,k) => {
				menus_local[item.ID] = item
				return false
			})
		}).catch((err) => {
			console.log(err);
		});	
		return menus_local
	}

	async fetch_categories() {
		const response = await this.go_fetch(this.state.api_base_url+"/categories?"+this.state.fields.category+"&per_page=100",false);
		let categories_local = {}
		let slugs_local = {};
		response.map((category,key) => {
			categories_local[category.id] = category;
			slugs_local[category.slug] = category.id;
			return false;
		})
		return {
			categories:categories_local,
			categories_slugs: slugs_local
		}
	}
	async fetch_tags() {
		const response = await this.go_fetch(this.state.api_base_url+"/tags?"+this.state.fields.tag+"&per_page=100&orderBy=id&order=desc",false);
		let tags_local = {}
		response.map((tag,key) => {
			tags_local[tag.id] = tag;
			return false;
		})
		return tags_local;
	}

	async fetch_posts_by_tag(tag_id,page_nr) {
		let response = []
		let posts_local = JSON.parse(JSON.stringify(this.state.posts))
		let posts_list_local = [];
		
		response = await this.go_fetch(this.state.api_base_url+"/posts?tags="+tag_id+"&"+this.state.fields.post+"&page="+page_nr+"&per_page="+this.state.per_page,true);
		response.map((post,key) => {
			posts_local[post.id] = post;
			posts_list_local.push(post.id)
			return false;
		})
		// ----> PREPROCESS ?!?
		posts_local = this.preprocess_posts(posts_local)
		this.fetch_mediaSets(posts_local);
		return {
			posts: posts_local,
			posts_list: posts_list_local
		}
	}


	async fetch_posts_by_category(category_id,page_nr) {
		let response = []
		let posts_local = JSON.parse(JSON.stringify(this.state.posts))
		let posts_list_local = [];
		if(category_id==="*") {
			response = await this.go_fetch(this.state.api_base_url+"/posts?page="+page_nr+"&"+this.state.fields.post+"&per_page="+this.state.per_page,true);
			response.map((post,key) => {
				posts_local[post.id] = post;
				posts_list_local.push(post.id)
				return false;
			})
			// ----> PREPROCESS ?!?
			posts_local = this.preprocess_posts(posts_local)
			this.fetch_mediaSets(posts_local);
			return {
				posts: posts_local,
				posts_list: posts_list_local
			}
		}
		else {
			response = await this.go_fetch(this.state.api_base_url+"/posts?categories="+category_id+"&"+this.state.fields.post+"&page="+page_nr+"&per_page="+this.state.per_page,true);			
			response.map((post,key) => {
				posts_local[post.id] = post;
				posts_list_local.push(post.id)
				return false;
			})
			
			// ----> PREPROCESS ?!?
			posts_local = this.preprocess_posts(posts_local)
			
			this.fetch_mediaSets(posts_local);
			return {
				posts: posts_local,
				posts_list: posts_list_local
			}
		}
	}

	async fetch_mediaSet(item) {
		if(item.hasOwnProperty('featured_media') && item.featured_media>0) {
			const mediaSet_raw = await this.go_fetch(this.state.api_base_url+"/media/"+item['featured_media']+"?"+this.state.fields.media);
			let mediaSet = this.fill_featured_mediaSet(mediaSet_raw)
			return mediaSet
		}
	}

	async fetch_mediaSets(data) {
		let mediaSets_local = JSON.parse(JSON.stringify(this.state.mediaSets))
		let queue = [];

		Helpers.o2a(data).items.map((item,key) => {
			if(item.hasOwnProperty("featured_media") && item.featured_media>0) {
				if(!mediaSets_local.hasOwnProperty(item.featured_media)) {
					queue.push(item.featured_media)
				}
			}
			return false
		})

		if(queue.length>0) {
			let queue_str = queue.join(",");
			this.go_fetch(this.state.api_base_url+"/media/?include="+queue_str+"&"+this.state.fields.media+"&per_page=100",false)
			.then((media) => {
				media.map((medium,key) => {
					mediaSets_local[medium.id] = this.fill_featured_mediaSet(medium);
					return false;
				})
				this.setState({
					mediaSets : mediaSets_local
				})
				return mediaSets_local
			})
		}
	}

	changeUrl(url, title) {
		// var new_url = '/' + url;
		// window.history.pushState('data', title, new_url);	
	}

	set_meta(meta) {
		let meta_local = JSON.parse(JSON.stringify(this.state.meta))
		Object.keys(meta_local).map((key, i) => {
			if(meta.hasOwnProperty(key)) {
				if(meta[key].length>0) {
					meta_local[key] = meta[key]
				}

				
			}
			return false
		})

		this.setState({
			meta:meta_local
		})

	}

	set_home() {
	// window.location = "./"
		this.setState({
			layout:"home",
			search_term : ""
		})
		this.set_category("*")
		document.getElementById("header").classList.remove("compacted");
		document.getElementById("main").style.marginTop = 0;
	}

	fill_featured_mediaSet(mediaData) {
		const featuredMediaSizes = ["full","large","medium","medium_large","thumbnail"]
		const fms = featuredMediaSizes.reverse();
		let featuredMediaInfo = {
			title: mediaData.title,
			slug: mediaData.slug,
			alt_text: mediaData.alt_text
		}
		let featuredMediaUrls = {
			full: "",
			large: "",
			medium: "",
			small: "",
			thumbnail: "",
		}

		let i=0;
		while(featuredMediaUrls.large==="") {
			if(mediaData.media_details.sizes.hasOwnProperty(fms[i])) {
				featuredMediaUrls.full=mediaData.media_details.sizes[fms[i]].source_url
				featuredMediaUrls.large=mediaData.media_details.sizes[fms[i]].source_url
				featuredMediaUrls.medium=mediaData.media_details.sizes[fms[i]].source_url
				featuredMediaUrls.small=mediaData.media_details.sizes[fms[i]].source_url
				featuredMediaUrls.thumbnail=mediaData.media_details.sizes[fms[i]].source_url
			}
			i++;
		}
		if(mediaData.media_details.sizes.hasOwnProperty("full")) {
			featuredMediaUrls.full=mediaData.media_details.sizes["full"].source_url
		}
		if(mediaData.media_details.sizes.hasOwnProperty("large")) {
			featuredMediaUrls.large=mediaData.media_details.sizes["large"].source_url
			featuredMediaUrls.medium=mediaData.media_details.sizes["large"].source_url
		}
		else if(mediaData.media_details.sizes.hasOwnProperty("medium-large")) {
			featuredMediaUrls.large=mediaData.media_details.sizes["medium-large"].source_url
			featuredMediaUrls.medium=mediaData.media_details.sizes["medium-large"].source_url
		}		
		else if(mediaData.media_details.sizes.hasOwnProperty("medium")) {
			featuredMediaUrls.large=mediaData.media_details.sizes["medium"].source_url
			featuredMediaUrls.medium=mediaData.media_details.sizes["medium"].source_url
		}
		

		let featured_mediaSet = {
			id: mediaData.id,
			info: featuredMediaInfo,
			sizes: featuredMediaUrls
		}
		
		return(featured_mediaSet)

	}

	go_fetch(url,calc_pagination) {
		return fetch(url)
		.then(
			(response) => {
				if(calc_pagination===true) {
					let iterator_entries = this.read_iterator(response.headers.entries(),"x-wp-total","x-wp-totalpages");
					//let navigation_pages = this.calc_pagination(this.state.page_nr,iterator_entries["x-wp-totalpages"],this.state.page_iterator_count)
					this.setState({
						x_wp_total : iterator_entries["x-wp-total"],
						x_wp_totalpages : iterator_entries["x-wp-totalpages"],
						//navigation_pages : navigation_pages
					})
				}	
				return response.json()
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
				console.log("error")
			}
		)
	}

	read_iterator(iterator,keys) {
		let entries = {}
		for (var pair of iterator) {
			entries[pair[0]] = parseInt(pair[1]);
		}
		return entries;
	}

	calc_pagination2(page,max_pages,page_iterator_count) {
		let pages = [];
		let backs = [];
		let nexts = [];

		for(var i=1;i<=page_iterator_count;i++) {
			let back = page-i;
			if(back>0) {
				backs.push(back)
			}
			let next = page+i;
			if(next<=max_pages) {
				nexts.push(next)
			}
			pages.push(i)
		}
		backs.sort(function(a, b) {
			return a - b;
		});
		//backs = backs.slice(backs.length-2);
		//nexts = nexts.slice(0,page_iterator_count-backs.length-1);
		pages = backs.concat([page]).concat(nexts);
		pages[0]=1
		
		if(pages.indexOf(max_pages)<=-1) {
			pages[pages.length-1]=parseInt(max_pages)
		}
		return pages;
	}

	calc_pagination(page,max_pages,page_iterator_count) {
		let pages = [];
		if(max_pages*1<page_iterator_count*1) {
			page_iterator_count = max_pages*1;
		}
		
		const try_push = function(c) {
			if(pages.length<page_iterator_count) {
				if(pages.indexOf(c)<=-1) {
					pages.push(c);
				}
			}
		}
		if(max_pages>0) {	
			pages = [1,max_pages];
			if(pages.indexOf(page)<=-1) {
				pages.push(page)
			}
			for(var i=1;i<=3;i++) {
				if(page-i>0) {
					try_push(page-i)
				}
				if(page+i<max_pages) {
					try_push(page+i)
				}
			}
		}
		pages.sort(function(a, b) {
			return a - b;
		});	
		pages = [...new Set(pages)];
		return pages
	}

	toggle_menu() {
		let output = this.state.menu_visible;
		if(this.state.menu_visible==="menu_visible") {
			output = "menu_hidden"
		}
		else {
			output = "menu_visible"
		}
		this.setState({
			menu_visible:output
		})
	}

	header_padding() {
		let hp = 0;
		if(document.getElementById("navigationBar")) {
			hp = document.getElementById("navigationBar").clientHeight
		}
		return (hp+"px")
	}

	render() {


		// const Home = () => (
		// 	<div className='home'>
		// 	  <h1>Welcome to my portfolio website</h1>
		// 	  <p> Feel free to browse around and learn more about me.</p>
		// 	</div>
		//   );
		
		//   const About = () => (
		// 	<div className='about'>
		// 	  <h1>About Me</h1>
		// 	  <p>Ipsum dolor dolorem consectetur est velit fugiat. Dolorem provident corporis fuga saepe distinctio ipsam? Et quos harum excepturi dolorum molestias?</p>
		// 	  <p>Ipsum dolor dolorem consectetur est velit fugiat. Dolorem provident corporis fuga saepe distinctio ipsam? Et quos harum excepturi dolorum molestias?</p>
		// 	</div>
		//   );
		
		//   const Contact = () => (
		// 	<div className='contact'>
		// 	  <h1>Contact Me</h1>
		// 	  <p>You can reach me via email: <strong>hello@example.com</strong></p>
		// 	</div>
		//   );

		// const MyRoutes = () => (
		// 	<Routes>
		// 		<Route path='/' element={<Home/>}></Route>
		// 		<Route path='/about' element={<About/>}></Route>
		// 		<Route path='/contact' element={<Contact/>}></Route>
		// 	</Routes>
		// );

		
		
		return(

			<div id='App' className={"App layout_"+this.state.layout+" "+this.state.menu_visible}>
				
				<MetaTags>
					<title>{this.state.meta.title}</title>
					<meta id="meta-description" 	name="description" 			content={this.state.meta.description} />
					<meta id="meta-kewyords" 		name="keywords" 			content={this.state.meta.keywords} />

					<meta id="og-url" 				property="og:url"			content={this.state.meta.url} />
					<meta id="og-title" 			property="og:title"			content={this.state.meta.title} />
					<meta id="og-description" 		property="og:description"	content={this.state.meta.description} />
					<meta id="og-image" 			property="og:image"			content={this.state.meta.image} />

				</MetaTags>

				<header className="header" id='header'>
					<div className='heroHeader' id='heroHeader'>
						<img className='heroPic' src='https://wp.rafenew.world/wp-content/uploads/2018/04/cropped-Mimimi-War_3_1_sans_medium.jpg' alt=''></img>
						<div className='mainTitle'>
							<h1
							onClick = 	{() => {
								this.set_home() 
								}
							}>
								rafe new world
							</h1>
							<div className='claim'>{"Data, Design & Storytelling"}</div>
							{/* <div className='underConstruction'>– Under Construction –</div> */}

						</div>
						
					</div>
					<div className='navigationBarWrapper' id='navigationBarWrapper'>
						<div className='navigationBar' id='navigationBar'>
							<div 
								className='rnwHeroLogo'
								onClick = 	{() => {
									this.set_home() 
									}
								}>
							</div>
							<MainMenu
								menus = {this.state.menus}
								id = {6}
								set_category = {this.set_category}
								navigate = {this.navigate}
								toggle_menu = {this.toggle_menu}
							>
							</MainMenu>
							<Breadcrumbs 
								categories = 	{this.state.categories}
								tags = 			{this.state.tags}
								navigate = 		{this.navigate}
								set_home = 		{this.set_home}
								set_page = 		{this.set_page}
								x_wp_total = 	{this.state.x_wp_total}
								category_id = 	{this.state.category_id}
								tag_id = 		{this.state.tag_id}
								page_nr = 		{this.state.page_nr}
								post_id = 		{this.state.post_id}
								post = 			{this.state.post}
								search_term	=	{this.state.search_term}
								set_search = 	{this.set_search}
							>
							</Breadcrumbs>
						</div>	
					</div>
					
					
					

				</header>
				<BrowserRouter>
				{/* <MainNavigation/> */}
					{/* <MyRoutes /> */}
				</BrowserRouter>
				
			
				<main className='main' id='main'
					style={{
						paddingTop: this.header_padding()
					}}
				
				>
					{this.state.type==="page" &&
					<section className='content'>
					{this.state.ready===false &&
						<div className='busy-wrapper'>
							<Busy
								size={"default"}
							></Busy>
						</div>
					}
						<Pagination
							page_nr = {this.state.page_nr}
							total_pages = {this.state.x_wp_totalpages}
							navigation_pages = {this.state.navigation_pages}
							navigate = {this.navigate}
							calc_pagination = {this.calc_pagination}
							page_iterator_count = {this.state.page_iterator_count}
						>
						</Pagination>
						<PostListing 
						posts = {this.state.posts}
						posts_list = {this.state.posts_list}
						categories = {this.state.categories}
						tags = {this.state.tags}
						mediaSets = {this.state.mediaSets}
						category = {this.state.category_id}
						navigate = {this.navigate}
						>
						</PostListing>
						<Pagination
							page_nr = {this.state.page_nr}
							total_pages = {this.state.x_wp_totalpages}
							navigation_pages = {this.state.navigation_pages}
							navigate = {this.navigate}
							calc_pagination = {this.calc_pagination}
							page_iterator_count = {this.state.page_iterator_count}
						>
						</Pagination>
					</section>
					}	
					{this.state.type==="post" &&
					<section className='content'>
						<PostPagination
							post_id =  {this.state.post_id}
							posts_list = {this.state.posts_list}
							posts = {this.state.posts}
							navigate = {this.navigate}
							page_nr = {this.state.page_nr}
							total_pages = {this.state.x_wp_totalpages}
						>
						</PostPagination>
						<Post
							number = {0}
							view = {"full"}
							post = {this.state.post}
							categories = {this.state.categories}
							tags = {this.state.tags}
							mediaSets = {this.state.mediaSets}
							navigate = {this.navigate}
							category = {this.state.category}
						>
						</Post>
						<PostPagination
							post_id =  {this.state.post_id}
							posts_list = {this.state.posts_list}
							posts = {this.state.posts}
							navigate = {this.navigate}
							page_nr = {this.state.page_nr}
							total_pages = {this.state.x_wp_totalpages}
						>
						</PostPagination>
					</section>
					}

					{this.state.type==="webpage" &&
					<section className='content'>
						<Webpage
							webpage = {this.state.webpage}
							mediaSets = {this.state.mediaSets}
							navigate = {this.navigate}
						>
						</Webpage>
					</section>
					}
					
					<aside className='aside'>
						<div className='searchWrapper'>
							{/* <h2>Search</h2> */}
							<div className='searchForm'>
								<input type="search" id="searchField" className="searchField" placeholder="Search" name="s"
									value={this.state.search_term}
									onChange={this.handle_search_field}
									onKeyDown={(keyevent) => {
										if (keyevent.keyCode === 13) {
											this.set_search()
										}
									}
									}

								></input>
								<button
								onClick= {() => {
									this.set_search(this.state.search_term) 
									}
								}
								type="button" className="search-submit">
									<span className="material-icons">search</span>
								</button>
							</div>
						</div>
						{/* <Contacts
							contacts = {this.state.contacts}
						></Contacts> */}
						<Menu
							menus = {this.state.menus}
							id = {442}
							set_category = {this.set_category}
							navigate = {this.navigate}
							toggle_menu = {this.toggle_menu}
						>
						</Menu>
						
						<Menu
							menus = {this.state.menus}
							id = {681}
							set_category = {this.set_category}
							navigate = {this.navigate}
							toggle_menu = {this.toggle_menu}
						>
						</Menu>
						<br clear='all'></br>
						<div className='spotifyPlayerWrapper'>
							<iframe 
								className='spotifyPlayer'
								title = {"spotify-player"}
								src="https://open.spotify.com/embed?uri=spotify%3Aplaylist%3A5jYAPBcTZ94NWQuJj9k51l" 
								frameBorder="0" 
								allowtransparency="true" 
								allow="encrypted-media"	
							>
							</iframe>
						</div>
						<Contacts
							contacts = {this.state.contacts}
						></Contacts>

					</aside>				
				
				
				</main>
				
				
				<footer className="footer">
					© rafenew.world 2022
				</footer>
			</div>
		)
	}
}


export default App;
