import React from 'react';
import Helpers from './Helpers';

import './Pagination.css';

class PostPagination extends React.Component {

	// shouldComponentUpdate(nextProps, nextState) {
	// 	let triggered = Helpers.update_trigger(this.props,nextProps);
	// 	return triggered;
	// }
	
	render() {
		let post_index = this.props.posts_list.indexOf(this.props.post_id);
		let pagination_items = [];

		let next_page = this.props.page_nr;
		if(next_page<this.props.total_pages) {
			next_page = next_page+1;
		}
		else {
			next_page = 1;
		}
		
		if(this.props.posts_list.length>0) {
			if(post_index>0) {
				let p = this.props.posts[this.props.posts_list[post_index-1]];
				pagination_items.push({
					title: Helpers.getDecodedString(p.title.rendered),
					type: "post",
					id: p.id,
					icons: ["keyboard_arrow_left",""]
				})
			}
			else {
				pagination_items.push({
					title: " more",
					type: "page",
					id: this.props.page_nr,
					icons: ["more_horiz",""]
				})
			}
			if(post_index<this.props.posts_list.length-1) {
				let p = this.props.posts[this.props.posts_list[post_index+1]];
				pagination_items.push({
					title: Helpers.getDecodedString(p.title.rendered),
					type: "post",
					id: p.id,
					icons: ["","keyboard_arrow_right"]
				})
			}
			else {
				pagination_items.push({
					title: "more ",
					type: "page",
					id: next_page,
					icons: ["","more_horiz"]
				})
			}

			return(
				<nav className='paginationWrapper'>
					<ul className='pagination'>
						{
						pagination_items.map((item,k) => {
							return(
								<li key = {k}
									className={'paginationItem postPaginationItem postPaginationItem_'+k}
									onClick = 	{() => {
										this.props.navigate(item.type,item.id) 
									}
								}
								>
									<span className='paginationText material-icons '>
										{item.icons[0]}
									</span>
									<span className='paginationText'>
										{item.title}
									</span>
									<span className='paginationText material-icons '>
										{item.icons[1]}
									</span>
								</li>
								)
							})
						}
					</ul>
				</nav>
			)
		}
		else {
			return false;
		}

	}
}

export default PostPagination
