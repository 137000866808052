import React from 'react';
import Helpers from './Helpers';

import './PostFooter.css';

class PostFooter extends React.Component {

	shouldComponentUpdate(nextProps, nextState) {
		let triggered = Helpers.update_trigger(this.props,nextProps);
		return triggered;
	}

	componentDidMount() {
	}
	
	render() {
		let post = this.props.post;
		return(
			<div className='PostFooter'>
				<div className='tagsTitle'>
					Tags: 
				</div>
				<ul className='postTags'>
				{							
					post.tags.map((tag_id,l) => {
						let tag = Helpers.lookupStrict(tag_id,this.props.tags,{name:tag_id});
						// console.log(tag1)
						if(tag) {
							return(
								<li key={l}>
									<div className='tagLink'
									onClick = 	{() => {
										this.props.navigate("tag",tag.id) 
										}
									}
									>
										{Helpers.getDecodedString(tag['name'])}
									</div>
									
								</li>
							)		
						}
						else return false
						
					})
				}
				</ul>
			</div>

		)
		

	}
}

export default PostFooter
