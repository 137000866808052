import React from 'react';
import Helpers from './Helpers';
import Busy from './Busy';
import Menu from './Menu';
import './MainMenu.css';

class MainMenu extends React.Component {

	shouldComponentUpdate(nextProps, nextState) {
		let triggered = Helpers.update_trigger(this.props,nextProps);
		return triggered;
	}

	render() {
		if(this.props.menus.hasOwnProperty(this.props.id)) {
			return(
				<nav className='MainMenuWrapper'>
					<div className='menuToggler'
						onClick = 	{() => {
							// this.props.navigate(item.object,item.object_id) 
							this.props.toggle_menu() 
							}
						}
					>
						Menu
						<span className='menuToggleIcon menuToggleIcon_closed material-icons'>
							&#xe5d2;
						</span>
						<span className='menuToggleIcon menuToggleIcon_open material-icons'>
							&#xe9bd;
						</span>
					</div>
					<Menu
						menus = {this.props.menus}
						id = {6}
						set_category = {this.props.set_category}
						navigate = {this.props.navigate}
						toggle_menu = {this.props.toggle_menu}
					></Menu>
					
				</nav>
			)	
		}
		else return (
			<div className='busy-wrapper'>
				<Busy
					size = {"small"}
				></Busy>
			</div>
			
		)
		
	}
}

export default MainMenu
