import React from 'react';
import Helpers from './Helpers';
import './FeaturedMedia.css';

class FeaturedMedia extends React.Component {
	render() {
		let article = this.props.post;
		let lazy = "auto";
		if(this.props.number>=5) {
			lazy = "lazy";
		}
		let featured_media = false;
		if(this.props.mediaSets.hasOwnProperty(this.props.post.featured_media)) {
			featured_media = this.props.mediaSets[article.featured_media];
		}
		return(
			<div className={'FeaturedMedia featured-container_16_9 '}
				key={article.id}
				onClick = 	{() => {
					if(this.props.view!=="full") {
						this.props.navigate("post",article.id) 
					}
				}
			}
			>
			{
				this.props.mediaSets.hasOwnProperty(this.props.post.featured_media) === true &&
				<img 	
					key = {article.featured_media}
					src = {featured_media.sizes[this.props.media_size]}
					alt = {Helpers.getDecodedString(featured_media.info.title.rendered)}
					loading = {lazy}
					className = {'featuredImagePic featuredImagePic_'+article.featured_media+' loading'}
					onLoad = {() => {
						document.querySelector('.featuredImagePic_'+article.featured_media).classList.remove("loading")
						}
					}
				/>
			}
			</div>	

			)		
	}
}

export default FeaturedMedia
