import React from 'react';
import DOMPurify from "dompurify";
import Helpers from './Helpers';
import './Breadcrumbs.css';

class Breadcrumbs extends React.Component {

	shouldComponentUpdate(nextProps, nextState) {
		let triggered = Helpers.update_trigger(this.props,nextProps);
		return triggered;
	}

	render() {
		return(
			<nav className='Breadcrumbs'>				
				<ul className='breadcrumbsWrapper'>
					<li className='breadcrumbItem breadcrumbItem_home'
						onClick = 	{() => {
							// this.props.navigate("page",this.props.page_nr) 
							this.props.set_home() 
						}
					}
					>
						<span className='breadcrumbHome material-icons'>
							arrow_drop_up
						</span>
						<span className='breadcrumbText'>
							Home
						</span>
					</li>
					{this.props.category_id!=="*" && 
					<li className={'breadcrumbItem breadcrumbItem_'+this.props.category_id}
						onClick = 	{() => {
							this.props.navigate("page",this.props.page_nr) 
							}
						}
					>
						<span className='breadcrumbSlash material-icons'>
							&#xe5df;
						</span>
						<span className='breadcrumbText'
						dangerouslySetInnerHTML={{
							__html: DOMPurify.sanitize(this.props.categories[this.props.category_id].name)
						}}
						></span>
					</li>
					}

					{this.props.tag_id!=="*" && 
					<li className={'breadcrumbItem breadcrumbItem_tag'}
						onClick = 	{() => {
							this.props.navigate("tag",this.props.tag_id) 
							}
						}
					>	
						<span className='breadcrumbSlash material-icons'>
							&#xe5df;
						</span>			
						<span className='breadcrumbText'
						dangerouslySetInnerHTML={{
							__html: DOMPurify.sanitize(this.props.tags[this.props.tag_id].name)
						}}
						></span>
					</li>
					}

						{this.props.search_term!=="" && 
						<li className={'breadcrumbItem breadcrumbItem_search'}
						onClick = 	{() => {
							this.props.set_search() 
							}
						}
					>
						<span className='breadcrumbSlash material-icons'>
							&#xe5df;
						</span>
						<span className='breadcrumbText'>
							Search: <i>{this.props.search_term}</i>
						</span>
					</li>
					}


					{this.props.post_id!=="*" && 
					<li className={'breadcrumbItem breadcrumbItem_post'}
					>
						<span className='breadcrumbSlash material-icons'>
							&#xe5df;
						</span>
						<span className='breadcrumbText'
							dangerouslySetInnerHTML={{
								__html: DOMPurify.sanitize(this.props.post.title.rendered)
							}}
						></span>
					</li>
					}

				</ul>

			</nav>
		)
	}
}

export default Breadcrumbs
