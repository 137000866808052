import React from 'react';
import Helpers from './Helpers';
import PostContent from './PostContent';
import FeaturedMedia from './FeaturedMedia';

import './Webpage.css';

class Webpage extends React.Component {

	shouldComponentUpdate(nextProps, nextState) {
		let triggered = Helpers.update_trigger(this.props,nextProps);
		return triggered;
	}

	// componentDidMount() {
	// 	window.scrollTo(0, 0)
	// }

	render() {
		let webpage = this.props.webpage;
		return (
			<article className={'Webpage'}
			>		
				{
				webpage.hasOwnProperty("featured_media")===true && webpage.featured_media>=0 &&
					<FeaturedMedia
						number = {this.props.number}
						webpage = {webpage}
						navigate = {this.props.navigate}
						view = {this.props.view}
						mediaSets = {this.props.mediaSets}
						media_size = "full"
					>
					</FeaturedMedia>
				}

				<PostContent
					content = {webpage.content.rendered}
				>
				</PostContent>				

			</article>
		)
	}
}

export default Webpage
