import React from 'react';
import Helpers from './Helpers';
import PostHeader from './PostHeader';
import PostFooter from './PostFooter';
import PostContent from './PostContent';
import FeaturedMedia from './FeaturedMedia';

import './Post.css';

class Post extends React.Component {

	shouldComponentUpdate(nextProps, nextState) {
		let triggered = Helpers.update_trigger(this.props,nextProps);
		return triggered;
	}


	componentDidMount() {
	}

	render() {
		let post = this.props.post;
		return (
			<article className={'Post postView_'+this.props.view}
			>
				<PostHeader
					post = {post}
					categories = {this.props.categories}
					navigate = {this.props.navigate}
					category = {this.props.category}
					mediaSets = {this.props.mediaSets}
					set_category = {this.props.set_category}
					view = {this.props.view}
				>
				</PostHeader>	
					
				{
				post.hasOwnProperty("featured_media")===true && post.featured_media>=0 &&
					<FeaturedMedia
						number = {this.props.number}
						post = {post}
						navigate = {this.props.navigate}
						view = {this.props.view}
						mediaSets = {this.props.mediaSets}
						media_size = "full"
					>
					</FeaturedMedia>
				}
				{
				(this.props.view==="full") &&	
				<PostContent
					content = {post.content.rendered}
				>
				</PostContent>
				}					
				<PostFooter
					post = {post}
					tags = {this.props.tags}
					navigate = {this.props.navigate}
					set_meta = {this.props.set_meta}
				>
				</PostFooter>

			</article>
		)
	}
}

export default Post
