import React from 'react';
import Helpers from './Helpers';

import './Contacts.css';

class Contacts extends React.Component {

	shouldComponentUpdate(nextProps, nextState) {
		let triggered = Helpers.update_trigger(this.props,nextProps);
		return triggered;
	}

	render() {
		return(
			<div className='Contacts'>
				<h2>Contact</h2>
				<ul className='contactsWrapper'>
					{
					this.props.contacts.map((item,key) => {
						return(
							<li className={'contactItem contactItem_'+item.id}
								key = {item.id}
							>
								<a 
									href={item.url}
									target={'_blank'}
									rel='noreferrer'
									title = {item.name}
								>
									<img 
										className='contactSymbol'
										src={'/img/contacts/logo_'+item.id+'.png' }
										alt = {item.name}

									></img>
									
								</a>
							</li>	
						)
					
							
					})	
					}
				</ul>
			</div>
			
		
		)
	}
}

export default Contacts
