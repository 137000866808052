import React from 'react';
import DOMPurify from "dompurify";
import Helpers from './Helpers';

import './PostHeader.css';

class PostHeader extends React.Component {

	shouldComponentUpdate(nextProps, nextState) {
		let triggered = Helpers.update_trigger(this.props,nextProps);
		return triggered;
	}
	
	render() {
		let article = this.props.post;
		return(
			<div className='PostHeader'>
				<div className='postDate'>
					{Helpers.formatDate(Helpers.string2date(article.date),"D. Month YYYY mm:ss")}
				</div>
				
				<h1 
					className='postHeaderTitle'
					onClick = 	{() => {
						if(this.props.view!=="full") {
							this.props.navigate("post",article.id) 
						}
					}
				}
				dangerouslySetInnerHTML={{
					__html: DOMPurify.sanitize(article.title.rendered)
				}}>
				</h1>
				<div className='categoriesWrapper'>
					<div className='categoriesTitle'>
						Categories: 
					</div>
					<ul className='postCategories'>
					{							
						article.categories.map((category,l) => {
							let cat = Helpers.lookup(category,this.props.categories,{name:""});
							return(
								<li key={l}>
									<div className='categoryLink'
									onClick = 	{() => {
										this.props.navigate("category",cat.id) 
										}
									}
									>
										{Helpers.getDecodedString(cat['name'])}
									</div>
									{/* <a 
										href={cat['link']}
										target='_blank'
									>
										{Helpers.getDecodedString(cat['name'])}
									</a> */}
								</li>
							)		
						})
					}
					</ul>
				</div>

			</div>

		)
		

	}
}

export default PostHeader
