import React from 'react';
import Helpers from './Helpers';

import './PostListing.css';
import Post from './Post';

class PostListing extends React.Component {

	componentDidMount() {
		window.scrollTo(0, 0)
	}


	render() {
		// console.log(this.props.tags)
		return(
			<section className='PostListing'>
				{
					Helpers.o2a(this.props.posts_list).items.map((id,k) => {
						let post = this.props.posts[id]
						return(
							<Post
								key = {post.id}
								view = {"list"}
								number = {k}
								post = {post}
								mediaSets = {this.props.mediaSets}
								categories = {this.props.categories}
								tags = {this.props.tags}
								// set_post = {this.props.set_post}
								// set_tag = {this.props.set_tag}
								set_category = {this.props.set_category}
								navigate = {this.props.navigate}
							>

							</Post>
						)

					})
					
				}
			</section>
		)
	}
}

export default PostListing
