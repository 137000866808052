import React from 'react';
// import DOMPurify from "dompurify";
import Helpers from './Helpers';

import './PostContent.css';

class PostContent extends React.Component {

	shouldComponentUpdate(nextProps, nextState) {
		let triggered = Helpers.update_trigger(this.props,nextProps);
		return triggered;
	}

	render() {
		return (
			<div className='PostContent'
				dangerouslySetInnerHTML={{
					__html: this.props.content
				}}>
			</div>
		)
	}
}

export default PostContent
