import React from 'react';
import DOMPurify from "dompurify";
import Helpers from './Helpers';

import './Menu.css';

class Menu extends React.Component {

	shouldComponentUpdate(nextProps, nextState) {
		let triggered = Helpers.update_trigger(this.props,nextProps);
		return triggered;
	}

	menuItems(children,level) {
			return(
				<ul className={'menuWrapper menu_level_'+level}>
					{
						children.map((item,k) => {
							let cls = ""
							if(item.hasOwnProperty("children")) {
								cls = "hasSubItems"
							}
							return(
								<li 
									className={'menuItem menuItem_level_'+level+" "+cls}
									key = {item.id}
								>
									<span className="subItemIcon material-icons">&#xe5da;</span>
									<span className='menuItemText'
									dangerouslySetInnerHTML={{
										__html: DOMPurify.sanitize(item.title)
									}}
									onClick = 	{() => {
										if(item.object==="custom") {
											this.props.navigate(item.object,item.url) 
										}
										else if(item.object==="page") {
											this.props.navigate("webpage",item.object_id) 
										}
										else {
											this.props.navigate(item.object,item.object_id) 
											}
										}
									}
									></span>
									<span className="material-icons subItemsIcon subItemsIcon_open">&#xE313;</span>
									<span className="material-icons subItemsIcon subItemsIcon_close">&#xE316;</span>

									{
										item.hasOwnProperty("children") &&
										this.menuItems(item.children,level+1)
									}
									
								</li>	
							)
						})

					}
				</ul>
			)
	}

	render() {
		let menu = this.props.menus[this.props.id];
		if(this.props.menus.hasOwnProperty(this.props.id)) {
			return(
				<div className={'Menu Menu_'+menu.slug}>
					<h2>{menu.name}</h2>
					{this.menuItems(menu.items,0)}
				</div>
				)	
		}
		else return false
	}

}

export default Menu
