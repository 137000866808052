import React from 'react';

const Helpers = {

	getUrlVars: function(){
		var vars = [], hash;
		var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
		for(var i = 0; i < hashes.length; i++) {
			hash = hashes[i].split('=');
			vars.push(hash[0]);
			vars[hash[0]] = hash[1];
		}
		return vars;
	},
	getUrlVar: function(name){
		if(this.getUrlVars().hasOwnProperty(name)) {
			return this.getUrlVars()[name];
		}
		else {
			return false;
		}
	},
	write: function(word,number) {
		if(number===1) {
			return word+"en";
		}
		else {
			return word
		}
	},

	o2a: function(obj) {
		const object = obj;
		const keys = Object.keys(object);
		let items = [];
		Object.keys(object).map((key, i) => (
			items.push(object[key])
		))
		return {
			keys: keys,
			items: items
		}
	},

	tsnds: function(number) {
		let output = number;

		if(isNaN(number)) {
			return number;
		}

		let absolute = Math.abs(number);
		
		if(absolute>=10000) {

			let minus = "";
			if(number<0) {
				minus = "-";
			}

			absolute = absolute.toString();

			let parts = absolute.split(".");
			let front = parts[0];
			let back = parts[1];
			
			let tsdA = [];
			let splits=Math.ceil(front.length/3)-1;
			
			for(let i=splits;i>=0;i--) {
				let f=3*i;
				let ar=front.substring(front.length-f,front.length-f-3);
				tsdA.push(ar);
			}
			const tsdS=tsdA.join("'");
			output = minus+tsdS;
			if(back*1>0) {
				output = output+"."+back;
			}
		}
		return output;
	},
	
	padZero: function(n) {
		if(parseInt(n)>=10) {
			return n
		}
		else {
			return "0"+n
		}
	},

	string2date: function(str) {
		let output = "";

		if(str.length>1) {
			if(new Date(str)) {
				output = new Date(str)
			}
			else {
				output = "";
			}
		}
		else {
			output = ""
		}
		return output
	},

	formatDate: function(date,format) {
		//console.log(date)
		const month_names = ["January","February","March","April","May","June","July","August","September","October","November","December"]
		const month_names_short = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sept","Oct","Nov","Dec"]
		let output = String(date);
		if(String(date).length>1) {
			switch(format) {
				case "D. M. YYYY": 
					output = date.getDate()+ ". " + (date.getMonth()+1) + ". " + date.getFullYear()
				break;					
				case "D. Mon.": 
				output = date.getDate()+ ". " + month_names_short[date.getMonth()] + ". "
				break;				
				case "D. Month YYYY": 
					output = date.getDate()+ ". " + month_names[date.getMonth()] + ", " +date.getFullYear()
				break;				
				case "D. Month YYYY mm:ss": 
					output = date.getDate()+ ". " + month_names[date.getMonth()] + " " +date.getFullYear() + ", " +date.getHours() + ":" +this.padZero(date.getMinutes())
				break;				
				case "D. Mon YYYY": 
					output = date.getDate()+ ". " + month_names_short[date.getMonth()] + " " +date.getFullYear()
				break;				
				default : 
					output = date.getDate()+ ". " + (date.getMonth()+1) + ". " + date.getFullYear()
				break;

			}			
		}
		return output
	},

	get_plural(word,count) {
		if(count<=1) {
			return word;
		}
		else {
			return (word+"s")
		}
	},

	write_date(str,format) {
		let d = this.string2date(str);
		return(this.formatDate(d,format));
	},

	lookup(key,dictionary,template) {	
		if(dictionary.hasOwnProperty(key)) {
			return dictionary[key]
		}
		else {
			return (
				template
			);
		}
	},

	lookupStrict(key,dictionary,template) {	
		if(dictionary.hasOwnProperty(key)) {
			return dictionary[key]
		}
		else {
			return (
				false
			);
		}
	},

	getDecodedString(str) {
		const txt = document.createElement('textarea');
		txt.innerHTML = str;
		return txt.value;
	},

	sorting: function(data,by,direction) {
		let local = JSON.parse(JSON.stringify(data)); // Deep Copy
		function sortNow(a,b) {
			let returner = 0;
			let a1 = a[by];
			let b1 = b[by];

			if(isNaN(a1) || isNaN(b1)) {
				a1 = a1.toString();
				b1 = b1.toString();
			}
			else {
				a1 = a1*1;
				b1 = b1*1;
			}

			if(a1>b1) {
				returner = 1*direction
			}
			else if(a1<b1) {
				returner = -1*direction
			}
			else {
				returner = 0;
			}
			return returner;

		}

		local.sort(sortNow);
		return local;
	},


	renderNotes: function(notes) {
		if(notes.length>0) {
			return(
				<ul className='noteList'>
					{
						notes.map((note, k) => {
							return (
								<li className = 'noteListItem' key = {k}>
									<small>
										{ note	}
									</small>									
								</li>
							)
						})
					}
				</ul>
			)
		}
	},

	renderOver: function(over) {
		if(over===true) {
			return(" ✓")
		}
		else {
			return ("")
		}
	},

	rephraser(str) {
		const dictionary = {
			"Democratic Party": "Demokraten",
			"Republican Party": "Republikaner"
		}
		if(dictionary.hasOwnProperty(str)) {
			return (dictionary[str])
		}
		else return(str)
	},

	getPercent(total,part) {
		let output = 0;
		if(total>0 && part>0) {
			output = Math.floor(part/total*100*100)/100;
		}
		return (output)
	},

	update_trigger_specific(current,next,triggers) {
		let triggered = false;
		if(triggers!==undefined) {
			triggers.map((trigger,k) => {
				if(next.hasOwnProperty(trigger) && current.hasOwnProperty(trigger)) {
					if(current[trigger] !== next[trigger]) {
						triggered = true;
					}
				}
				return false;
			})
		}
		return triggered;
	},

	update_trigger(current,next) {
		let triggered = false;
		Object.keys(current).map((key, i) => {
			if(current[key]!==next[key]) {
				triggered = true;
			}
			return false;
		})
		return triggered;
	},

	convertHexColor(hexCode,opacity){
		var hex = hexCode.replace('#','');
		if (hex.length === 3) {
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
		}
	
		var r = parseInt(hex.substring(0,2), 16),
			g = parseInt(hex.substring(2,4), 16),
			b = parseInt(hex.substring(4,6), 16);
	
		return 'rgba('+r+','+g+','+b+','+opacity/100+')';
	},

	shuffleArray(array) {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}
		return array;
	}




}


export default Helpers;

