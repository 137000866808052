import React from 'react';
import './Busy.css';

class Busy extends React.Component {
	render() {	
		return (
			<div className={'Busy '+this.props.size}>
				<div className='loading'>
					<div className='spinnerRing'><div></div><div></div><div></div><div></div></div>
				</div>				
			</div>

		)
	}
}

export default Busy
